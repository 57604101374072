<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-flex xs2>
            <v-menu
              ref="menu1"
              v-model="beginDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedMonth"
                  :label="$t('global.month')"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" type="month" color="primary" @input="beginDateMenu = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>

        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          single-expand
        >
          <template slot="item" slot-scope="row">
            <tr :key="row.item.id">
              <td class="text-xs-left no-wrap">
                <employee-profile :employee="row.item.overtime.employee" />
              </td>

              <td>
                {{ row.item.overtime.workplan.date | formattedDate }}
              </td>

              <td>
                {{ $t(`request_status.${row.item.requestStatus}`) }}
              </td>
              <td>
                {{ row.item.overtime.reason }}
              </td>
              <td>
                {{ row.item.overtime.requestedDateTime | formattedDate }}
              </td>

              <td>
                <v-btn
                  v-if="row.item.overtime.description"
                  icon
                  @click.stop.prevent="
                    row.expand(!row.isExpanded);
                    activeTab = 0;
                  "
                >
                  <v-icon v-if="row.isExpanded"> mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon></v-btn
                >
              </td>
              <td class="no-wrap">
                <v-btn small dark depressed color="primary" @click.stop="onClickLeaveApproval(row.item)">
                  {{ $t("buttons.action") }}
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <tr>
              <td :colspan="headers.length" class="pa-0">
                <v-card class="rounded-0" color="grey lighten-3" elevation="0">
                  <v-card-title class="primary--text">{{ $t("global.description") }}</v-card-title>
                  <v-card-text class="px-4">
                    {{ item.overtime.description }}
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <approval-confirm-dialog
      v-model="dialog.status"
      :data.sync="dialog.data"
      @close="onCloseDialog"
      @success="onSuccessApproval"
    />
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description shifts Component Logic
   * @date 25.07.2020
   */
  import { OVERTIME_APPROVAL } from "../query";

  export default {
    name: "OvertimeApproval",
    components: {
      "approval-confirm-dialog": () => import("./components/confirm-dialog")
    },
    data: vm => ({
      dialog: {
        status: false,
        data: null
      },
      beginDateMenu: false,
      date: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM"),
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [],
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "overtime.requestedDateTime",
          descending: false
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("time.overtime_approval.employee"),
            align: "left",
            sortable: false,
            value: "firstName",
            class: ["primary--text text-lg-left"]
          },

          {
            text: vm.$t("time.overtime_approval.workplan"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("time.overtime_approval.request_status"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("time.overtime_approval.reason"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },

          {
            text: vm.$t("time.overtime_approval.request_time"),
            align: "left",
            sortable: false,
            value: "requestDateTime",
            class: ["primary--text text-lg-left"]
          },

          {
            text: vm.$t("global.description"),
            align: "left",
            sortable: false,
            class: ["primary--text text-lg-left"]
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.table.criteria.employees = [this.$store.state.auth.user.id];

          this.fetchItems();
        },
        deep: true
      },
      date: {
        handler(v) {
          this.table.criteria.beginDate = this.$moment(v)
            .startOf("month")
            .format("YYYY-MM-DD");
          this.table.criteria.endDate = this.$moment(v)
            .endOf("month")
            .format("YYYY-MM-DD");
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    computed: {
      formattedMonth() {
        return this.$moment(this.date).format("MMMM");
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: OVERTIME_APPROVAL,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.table.data = data.employee.pendingApprovalOvertimeRequests || [];
              this.table.totalItems = data.employee.pendingApprovalOvertimeRequests.length || 0;
              this.table.totalPages = data.employee.pendingApprovalOvertimeRequests.length || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      getLeaveStatusIconText(leaveStatus) {
        return this.$t(`leave_request_status.${leaveStatus}`);
      },
      getLeaveStatusIcon(leaveStatus) {
        let icon = "";
        switch (leaveStatus) {
          case "PENDING":
            icon = "hourglass_empty";
            break;
          case "APPROVED":
            icon = "done_all";
            break;
          case "CANCELLED":
            icon = "clear";
            break;
          case "NEED_EXTRA_DETAIL":
            icon = "info";
            break;
          case "DENIED":
            icon = "block";
            break;
          default:
            icon = "help";
            break;
        }
        return icon;
      },
      getLeaveStatusIconColor(status) {
        let color = "";
        switch (status) {
          case "PENDING":
            color = "orange";
            break;
          case "APPROVED":
            color = "green";
            break;
          case "CANCELLED":
            color = "grey";
            break;
          case "NEED_EXTRA_DETAIL":
            color = "blue";
            break;
          case "DENIED":
            color = "red";
            break;
          default:
            color = "black";
            break;
        }
        return color;
      },
      onClickLeaveApproval(overtime) {
        this.dialog.data = overtime;
        this.dialog.status = true;
      },
      onCloseDialog() {
        this.dialog.data = null;
        this.dialog.status = false;
      },
      onSuccessApproval() {
        this.onCloseDialog();
        this.onRefreshData();
      }
    }
  };
</script>

<style scoped></style>
