var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","tile":""}},[_c('v-card-title',{staticClass:"grey lighten-4"},[_c('v-flex',{attrs:{"xs2":""}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('global.month')},model:{value:(_vm.formattedMonth),callback:function ($$v) {_vm.formattedMonth=$$v},expression:"formattedMonth"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.beginDateMenu),callback:function ($$v) {_vm.beginDateMenu=$$v},expression:"beginDateMenu"}},[_c('v-date-picker',{attrs:{"type":"month","color":"primary"},on:{"input":function($event){_vm.beginDateMenu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-spacer'),_c('id-button',{on:{"click":_vm.onRefreshData},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t("buttons.refresh"))+" ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"items":_vm.table.data,"options":_vm.table.options,"server-items-length":_vm.table.totalItems,"single-select":"","loading":_vm.table.loading,"footer-props":{ 'items-per-page-options': [10, 25, 50, 100] },"single-expand":""},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',{key:row.item.id},[_c('td',{staticClass:"text-xs-left no-wrap"},[_c('employee-profile',{attrs:{"employee":row.item.overtime.employee}})],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("formattedDate")(row.item.overtime.workplan.date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$t(("request_status." + (row.item.requestStatus))))+" ")]),_c('td',[_vm._v(" "+_vm._s(row.item.overtime.reason)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formattedDate")(row.item.overtime.requestedDateTime))+" ")]),_c('td',[(row.item.overtime.description)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();row.expand(!row.isExpanded);
                  _vm.activeTab = 0;}}},[(row.isExpanded)?_c('v-icon',[_vm._v(" mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()],1),_c('td',{staticClass:"no-wrap"},[_c('v-btn',{attrs:{"small":"","dark":"","depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.onClickLeaveApproval(row.item)}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.action"))+" ")])],1)])]}},{key:"expanded-item",fn:function(ref){
                  var headers = ref.headers;
                  var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"rounded-0",attrs:{"color":"grey lighten-3","elevation":"0"}},[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t("global.description")))]),_c('v-card-text',{staticClass:"px-4"},[_vm._v(" "+_vm._s(item.overtime.description)+" ")])],1)],1)])]}}])})],1)],1),_c('approval-confirm-dialog',{attrs:{"data":_vm.dialog.data},on:{"update:data":function($event){return _vm.$set(_vm.dialog, "data", $event)},"close":_vm.onCloseDialog,"success":_vm.onSuccessApproval},model:{value:(_vm.dialog.status),callback:function ($$v) {_vm.$set(_vm.dialog, "status", $$v)},expression:"dialog.status"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }